import React from 'react';
import styled from "styled-components";
import twitterLogo from "./logos/x.png";
import discord from "./logos/discord.png";
import opensea from "./logos/opensea.png";
import nftpay from "./logos/nftpay.png"

const SocialMediaContainer = styled.div`
  position: fixed;
  left: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
`;

const SocialIcon = styled.a`
  margin: 8px;
  color: var(--primary);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }

  img {
    height: 40px; // Adjust the size as needed
    width: 40px; // Make it the same as height for a perfect circle
    border-radius: 50%; // This will make the image round
    object-fit: cover; // This will ensure the image covers the area without being stretched
  }
`;

export const SocialMediaFloat = () => {
    return (
        <SocialMediaContainer>
            <SocialIcon href="https://twitter.com/offroadstudios" target="_blank">
          <img src={twitterLogo} alt="Twitter" />
            </SocialIcon>

            <SocialIcon href="https://discord.gg/PjYt7JC5Mj" target="_blank">
          <img src={discord} alt="discord" />
            </SocialIcon>

        <SocialIcon href="https://opensea.io/collection/lafzart" target="_blank">
          <img src={opensea} alt="Opensea" />
            </SocialIcon>

            <SocialIcon href="https://nftpay.xyz" target="_blank">
          <img src={nftpay} alt="nftpay" />
            </SocialIcon>
            {/* Add more social icons as needed */}
        </SocialMediaContainer>
    );
};
