import styled from 'styled-components';
import React, { useState, useEffect } from 'react';


const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 25px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; // This is necessary for absolute positioning of the children
  padding-bottom: 20px; // Add padding to accommodate the close button
`;

const CloseButtonContainer = styled.div`
  position: absolute; // Position the container absolutely to the parent (PopupContent)
  bottom: 10px;       // Distance from the bottom of the PopupContent
  left: 0;
  right: 0;
  text-align: center; // This centers the button text
`;

const CloseButton = styled.button`
  display: inline-block; // Allows the button to size according to its content
  margin: 0 auto;        // Centers the button in the CloseButtonContainer
  padding: 10px 20px;    // Add padding for button size
  background: #f2f2f2;   // Background color for the button
  border: 1px solid #ccc;// Border color
    font-weight: bold;

  border-radius: 20px;   // Rounded corners
  cursor: pointer;       // Changes the cursor on hover
  outline: none;         // Removes the outline
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); // Adds a subtle shadow
  font-size: 16px;       // Text size
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */

  &:hover {
    background-color: #c3b6df; /* Slightly darker purple on hover for feedback */
  }

  &:active {
    background-color: #5a34a3; /* Even darker purple to simulate a click effect */
  }

  &:focus {
    outline: none; /* Removes the outline to keep the design clean */
  }
  // Add more styles as needed
`;
const NFTpayButton = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    // Function to handle button click and show popup
    const handleButtonClick = () => {
        setPopupVisible(true);
    };

    // Dynamically inject the NFTpay script
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://payments.nftpay.xyz/libs/iframe_inject.js?contract_uuid=5d5ba499-0167-4405-95d6-29060ccd6477";
        script.id = "creatify_script";
        document.body.appendChild(script);

        // Cleanup script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <CloseButton onClick={handleButtonClick}>
                Buy with card <span id="price-5d5ba499-0167-4405-95d6-29060ccd6477">
                    <img className='nftpay_spinner' src='https://payments.nftpay.xyz/img/icons/spinner2.svg' />
                </span>
            </CloseButton>
            {isPopupVisible && (
                <PopupOverlay onClick={() => setPopupVisible(false)}>
                    <PopupContent onClick={(e) => e.stopPropagation()}>
                        <iframe
                            title="NFT Payment"
                            height="550"
                            style={{ border: 'none' }}
                            src="https://payments.nftpay.xyz/iframe/iframe_pay/5d5ba499-0167-4405-95d6-29060ccd6477"
                        ></iframe>

                        <CloseButtonContainer>
                            <CloseButton onClick={() => setPopupVisible(false)}>Close</CloseButton>

                        </CloseButtonContainer>
                    </PopupContent>
            
                </PopupOverlay>
            )}
        </>
    );
};

export default NFTpayButton;