import React, { useEffect } from 'react';


const CrossmintPayButton = () => {
  useEffect(() => {
    // Optional: Dynamically load the Crossmint script if it's not already in your index.html
    const script = document.createElement('script');
    script.src = "https://unpkg.com/@crossmint/client-sdk-vanilla-ui@latest/dist/index.global.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <crossmint-pay-button
      projectId="ad3f53f4-ae5b-495e-9e94-08beb19570f5"
      collectionId="6dcbced1-5cc7-459e-a2f0-f3d7ab3ba343"
      mintConfig='{
        "totalPrice":"0.22",
        "_numTokens":"1"
      }'
      environment="production"
    ></crossmint-pay-button>
  );
};

export default CrossmintPayButton;
